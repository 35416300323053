:root {
    --darkest-blue: #0a0e11;
    --dark-blue: #11181e;
    --blue: #182028;
    --light-blue: #1e2932;
    --lighter-blue: #222a32;

    --dark-grey: #696969;
    --grey: #9c9c9c;
    --light-grey: #cccccc;

    --main: #3174d8;
}