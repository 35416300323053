@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

.app {
  text-align: center;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

body {
  margin: 0;
  background-color: var(--blue);
  color: var(--light-grey);
}

button {
  color: #fff;
  outline: none;
  font-size: 14px;
  border: none;
  padding: 10px;
  margin: 2px 5px;
  border-radius: 5px;
  background-color: var(--lighter-blue);
  transition: all 0.2s ease;
}

button:hover,
button:disabled {
  background-color: var(--dark-blue);
}