.attendances-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.attendancesText {
    opacity: 0.6;
    font-weight: 100;
    font-size: 0.8rem;
    padding: 2px;
    text-align: end;
}