.nav {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark-blue);
    top: 0;
    width: 100%;
    padding: 20px 0px;
    z-index: 100;
    transition: 0.5s all;
    opacity: 0;
    animation: loginNavbar 0.5s linear forwards;
}

@keyframes loginNavbar {
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }    
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.nav-placeholder {
    height: 110px;
    margin: 5px 0;
    padding: 20px 0px;
}

.nav .site-logout {
    width: 30px;
    height: 30px;
    margin: 20px;
    background-image: url("../../icons/logout.svg");
    transition: path 0.2s ease-in-out;
    cursor: pointer;
}

.nav .site-preview-data {
    margin: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--darkest-blue);
    cursor: pointer;
}

.site-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ddd;
    text-decoration: none;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
}

.site-title img {
    margin: 2px;
    opacity: 0.8;
}

.site-title div {
    opacity: 0.9;
}

small {
    display: block;
    width: 100%;
    text-align: left;
    font-size: clamp(0.9rem, 1.5vw, 1rem);
    opacity: 0.75;
}

.title-links-section {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.nav .site-logout:hover,
.nav .site-logout:active {
    background-image: url("../../icons/logout-hover.svg");
    transition: path 0.2s ease-in-out;
}