.grades-averages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 5px;
}

.grades-averages p {
    font-size: clamp(0.85rem, 1vw, 0.9rem);
    padding: 2px;
    opacity: 75%;
}

table {
    width: 100%;
}

table tr {
    display: flex;
    justify-content: space-between;
}

table th {
    font-size: clamp(0.75rem, 1.5vw, 0.8rem);
}

table td {
    font-size: clamp(0.75rem, 1.5vw, 0.8rem);
}