.subject-card {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    color: #fff;
    background-color: var(--light-blue);
    padding: 10px;
    width: var(--subject-card-width);
    min-height: var(--subject-card-height);
    text-align: left;
    border-radius: 10px;
    margin: auto;
    box-shadow: 0px 8px 10px 0px rgba(19, 19, 19, 0.8);
}

.subject-card>div:not(.subject-card-grades) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.subject-card-text-div {
    width: 100%;
    overflow: hidden;
}

.attendance {
    width: 100%;
}

.subject-card-title-div {
    overflow: hidden;
    height: clamp(1.6rem, 2vw, 1.8rem);
}

.subject-card-title {
    font-size: clamp(0.95rem, 2vw, 1.05rem);
    font-weight: 400;
    overflow-wrap: break-word;
    padding: 2px 2px 0 2px;
}

.text-scroll-animation {
    animation: text-scroll 4s infinite forwards ease-in-out;
}

@keyframes text-scroll {
    0% {
        transform: translateY(0%);
    }

    45% {
        transform: translateY(-55%);
    }

    50% {
        transform: translateY(-55%);
    }

    95% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(0%);
    }
}