.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 300px;
    animation: loginInitialization 0.3s ease-in-out forwards;
}

@keyframes loginInitialization {
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }    
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

form p {
    font-size: 14px;
    padding: 4px;
}

form a {
    color: #fff;
}

.input-div {
    position: relative;
    margin: 20px;
    width: 100%;
}

.input-div label {
    position: absolute;
    transform: translateY(-27px);
    color: #fff;

    display: block;
    cursor: text;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    top: 30px;
}

.input-div input {
    caret-color: #fff;
    width: 100%;
    border: none;
    border-bottom: 4px solid var(--light-blue);
    background-color: transparent;
    color: #fff;

    outline: none;
    padding: 5px 5px;
}

.input-div .underline {
    position: absolute;
    bottom: 0px;
    height: 4px;
    width: 0%;
    background-color: var(--dark-blue);
    transition: all 0.2s ease;
}

.input-div input:focus~.underline,
.input-div input:valid~.underline,
.input-div input:not(:placeholder-shown)~.underline {
    width: 100%;
    transition: all 0.2s ease;
}

.input-div input:focus~label,
.input-div input:valid~label,
.input-div input:not(:placeholder-shown)~label,
input:-webkit-autofill+label {
    transform: translateY(-50px);
    transition: all 0.3s ease;
    font-size: 0.75rem;
    color: var(--light-grey);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--blue) inset !important;
    -webkit-text-fill-color: white !important;
}

.login-button {
    width: 100%;
}

.login-footer {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-blue);
    bottom: 10px;
    border-radius: 8px;
    left: 2%;
    width: 96%;
    z-index: 100;
    padding: 20px 10px;
    transition: 0.5s all;
    animation: footerInitialization 0.8s linear forwards;
}

@keyframes footerInitialization {
    0% {
        transform: translateY(200px);
    }
    100% {
        transform: translateY(0px);
    }
}

.login-footer p {
    font-size: clamp(14px, 2vw, 16px);
    margin-right: 20px;
    margin-left: 2px;
}

.login-footer button {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
}

.loader {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 5px solid;
    border-color: var(--main) transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error {
    width: 80px;
    height: 80px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    fill: #b42020;
}

.login main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.disabled {
    display: none !important;
}

input:disabled {
    background-color: var(--lighter-blue) !important;
}

.loader-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 40px;
    animation: revealAnimation 0.4s forwards;
}

.loader-div p {
    margin: 10px;
    width: 100%;
}

.error-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20px;
    animation: revealAnimation 0.4s forwards;
}

.logged-out-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20px;
    animation: revealAnimation 0.4s forwards;
}

.logged-out {
    width: 80px;
    height: 80px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
}

@keyframes revealAnimation {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }    
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}