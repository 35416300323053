.subjects-cards {
    display: grid;
    gap: 15px;
    padding: 5px;
    grid-template-columns: repeat(auto-fill, minmax(var(--subject-card-width), 1fr));
    grid-template-rows: auto;
    grid-auto-flow: dense;
    animation: Test 1s;
}

.sub-nav {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 10px;
}

.sub-nav .group {
    display: flex;
    height: 100%;
    padding: 10px;
}

.sub-nav p  {
    margin: 10px;
}

.sub-nav button {
    color: #fff;
    outline: none;
    font-size: 14px;
    border: none;
    padding: 10px;
    margin: 2px 5px;
    border-radius: 5px;
    background-color: var(--lighter-blue);
    transition: all 0.1s ease;
    cursor: pointer;
}

.sub-nav button:active {
    background-color: var(--dark-blue);
}

.sub-nav .active {
    background-color: var(--dark-blue) !important;
}

input[type="search"] {
    width: 20vw;
    min-width: 275px;
    margin: 10px;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: var(--lighter-blue);
    font-size: 15px;
    color: #fff;
    border: none;
}

@keyframes Test {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@media screen and (max-width: 640px) {
    .sub-nav {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        margin: 10px;
    }
}