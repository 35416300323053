.progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 14px;
}

.progress-bar .progress {
    animation: ProgressBarAnimation 0.75s ease-in-out forwards;
} 

.progress-bar-title {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 800;
    color: #000;
    opacity: 0.75;
    z-index: 1;
}

@keyframes ProgressBarAnimation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}